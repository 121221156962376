import logo from './logo.svg';
import mysins from './img/sin.svg';
import './App.css';
import "bootstrap/dist/css/bootstrap.min.css";
import { Modal, InputGroup, Card} from 'react-bootstrap';
import { renderToString } from 'react-dom/server';
import Chart from "react-apexcharts";

import "bootstrap/dist/js/bootstrap.bundle.min";

import presence from "./img/presence.svg"
import clock from "./img/clock.svg"
import sin from "./img/sin.svg"
import odin from "./img/odin.svg"
import allsin from "./img/allsin.svg"
import restore from "./img/restore.svg"
import machinetask from "./img/machinetask.svg"
import qr from "./img/qr.svg"

import { useEffect, useState } from 'react';

import {Table, Tag, Input, Form, message, Button} from 'antd';
import CardHeader from 'react-bootstrap/esm/CardHeader';
import { offset } from '@popperjs/core';



class emp {
  constructor(_name) {
    this.name = _name;
  }
  penalties() {
    let data = {
      late : 1,
      ovtime : 2
    }
    return data.late + data.ovtime
  }  
}

const _photomockup = <div className='container-fluid text-center'>
                      <img className='rounded-1' src={'https://images.tokopedia.net/img/cache/215-square/shops-1/2019/10/17/2965360/2965360_847d56f8-c052-442f-b528-7db37ab25837.jpg'}/>
                    </div>

const _photoprofile = <div className='m-2 container-fluid text-center'>
                        <img className='rounded-circle circ' src={'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png'}/>
                      </div>

function Gate() {

  const[getSignGate, setSignGate] = useState(null);
  const[getGate, setGate] = useState(null);
  const[getUser, setUser] = useState(null);






  async function redirect (value) {

    // let authdata = {
    //   "uname": value.username,
    //   "upass": "romanoff7",
    //   "uagent": "checklist-0.0.1",
    //   "cookie": ""}
    let authdata = {
      "uname": value.username,
      "upass": value.password,
      "uagent": "checklist-0.0.1",
      "cookie": ""}


    await fetch('https://fate.valkyrie.ptohpnk.com/caehfaenfdvngaijbanvsasdafhwhrtlastest/V8fiX3lpyUF9', {
              method : 'POST',
              headers:{
                  'Content-Type':'application/json'
              },
              body : JSON.stringify(authdata)
          }).then(
              _res => _res.text()
          ).then(
              _data => { 
                _data = JSON.parse(atob(atob(_data)));


                setUser(_data.token)

                setSignGate(true)
                setGate(true)
              }
          )


    
  }



  function unredirect () {
    setSignGate(false)
  }

  function alertFailed (stat) {
    if(stat == false){
      return <Tag color={"red"}>Kata Sandi Salah</Tag>
    } else if(stat == true){
      return <Tag color={"green"}>Berhasil</Tag>
    }
  }

  let formCont = <div className='m-3'>
                
                <Form
                  onFinish={redirect}
                  onFinishFailed
                  ={unredirect}
                >
                  <Form.Item 
                      
                      label = "Username"
                      name = "username"
                      rules={[
                        {
                          required:true,
                          message:"Username belum diisi"}
                      ]}>
                    <Input className='noborder'/>
                  </Form.Item>

                <Form.Item
                    label = "Password"
                    name = "password"
                    rules = {
                      [
                        {
                          required : false,
                          message : "Kata Sandi belum diisi"
                        }
                      ]
                    }

                    className='noborder'
                  >
                  <Input.Password className='noborder'/>
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Sign In
                  </Button>
                </Form.Item>
                <Form.Item className='noborder'>
                    {alertFailed(getSignGate)}
                </Form.Item>
              </Form>



      </div>


    let portalGate = <div className='row'>
                      <div className='col-sm-9 col-md-7 col-lg-5 mx-auto'>
                          <div className='card border-0 shadow'>
                            <div className='card-header'>
                              <h3>OH!Print Checklist v2</h3>
                            </div>
                            <div className='card-body'>

                              {_photomockup}
                              

                              {formCont}
                            </div>
                          </div>
                      </div>
                    </div>





    const [_get_showsin, _set_showsin] = useState(false);
    const [_get_submitsin, _set_submitsin] = useState(true);
    const [_get_myprofile, _set_myprofile] = useState(false);


    const showModal = {
      showSin : () => {
        _set_showsin(true)
      },
      submitSin : () => {
        _set_submitsin(true)
      },
      myProfile : () => {
        _set_myprofile(true)
      }
    }

    const closeModal = {
      showSin : () => {
        _set_showsin(false)
      },
      submitSin : () => {
        _set_submitsin(false)
      },
      myProfile : () => {
        _set_myprofile(false)
      }
    }

    let chkbody =
      <div className="App">
        <header className="App-header">
          <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"/>
        </header>
        <body>
          <div className='m-2'>
            <img src={odin} className="App-logo packing _logo rounded-circle nativeEvent" onClick={showModal.myProfile}/>
          </div>
          <div className='m-2'>
            <img src={sin} className="App-logo packing _logo rounded-circle nativeEvent" onClick={showModal.showSin}/>
          </div>
          <div className='m-2'>
            <img src={allsin} className="App-logo packing _logo rounded-circle nativeEvent" onClick={showModal.submitSin}/>
          </div>
          {_modalProfile(_get_myprofile, closeModal.myProfile)}
          {_modalSubmitSin(_get_submitsin, closeModal.submitSin)}
          {_modalShowSin(_get_showsin, closeModal.showSin)}
        </body>
      </div>

    if(getGate == null){
      return portalGate;
    } else if(getUser) {
      document.cookie = `${getUser}; Max-Age=72000; path=/;`
      return chkbody;
    }
  
}


//3 main feature
//showSin, submitSin, myProfile

function App() {

 
}

class InputList {
  constructor(_custom) {
    this.fieldname = _custom.fieldname;
    this.datalist = `list=${this.datalist}` || '';
    this.placeholder = _custom.placeholder || '';
    this.type = _custom.type || 'text';
    this.fieldclass = _custom.fieldclass || '';
    this.controller = '';
    this.value = _custom.value || '';
    this.inputclass = _custom.inputclass || '';

    this.input = <input class="oknuminp" className='useBorder col' type={this.type} placeholder={this.placeholder} value={this.value} />;

    this.inputqty = _custom.q || 1;

    this.input = <div className='col'>
      <input className='useBorder col' type={this.type} placeholder={this.placeholder} value={this.value} />
    </div>;


    if (_custom.controller) {

      let inpElm =` <div class='col my-1'>
                      <input class='useBorder col' type="${this.type}" placeholder="${this.placeholder}" value="${this.value}" />
                    </div>`

      this.input = (
          <div className='container col' id='penaltycont' ctrlmeta={btoa(inpElm)}>
            <div className='col my-1'>
                <input className='useBorder col' type={this.type} placeholder={this.placeholder} value={this.value} />
              </div>
          </div>
        );

    }

    return (
      <div className='row text-start'>
        <div className='col'>
          <p className={this.fieldclass}>{this.fieldname}</p>
        </div>
        {this.input}
        <datalist id={this.datalist}>
          {this.datalist}
        </datalist>
      </div>
    );
  }
}

class TableBlue {
  constructor (custom) {
    
  let _custom = custom;
  
  this.keys = Object.keys(_custom);

  let pointer = 0;

  let tbcontent = this.keys.map(_key => {

    let _leftmark = ""
    let _rightmark = ""
    let _value = _custom[_key];

    if(pointer%2 == 0){
      _leftmark = " y-a"
      _rightmark = " y-b"
    }

    pointer++

    return <div className="row">
              <div className={`col ${_leftmark}`}>
                <p style={{"font-weight":"bold"}}>{_key}</p>
              </div>
              <div className={`col ${_rightmark}`}>
                <p>: {_value}</p>
              </div>
          </div>
  });

  return (
    <div>
      {tbcontent}
    </div>
  )
  }
}


class PlusMinus {

  constructor(custom) {

    let _custom = {};

    if(custom != undefined) {
      _custom = custom
    }

    this.elm = _custom.elm || '';

    this.state = _custom.state || 'danger';
    
    this.nativeEventPlus = _custom.nativeEventPlus || 'click';
    this.nativeEventMinus = _custom.nativeEventMinus || 'click';

    this.nativeFuncPlus= _custom.nativeFuncPlus || 'emptyCallback';
    this.nativeFuncMinus= _custom.nativeFuncMinus || 'emptyCallback';

    this.btnsize = `col-${_custom.btnsize}` || 'col';
    

    return <div className='row m-1 text-end'>
              <div className={`col-6 col text-end`}>
              </div>
              <div className={`col-6 col text-end`}>
                <div className='row text-end'>
                  <i class={`mx-1 fa ${this.btnsize} fa-plus btn btn-${this.state} nativeEvent`} nativeEvent={this.nativeEventPlus} nativeFunc={this.nativeFuncPlus} elm={this.elm}/>
                  <i class={`mx-1 fa ${this.btnsize} fa-minus btn btn-${this.state} nativeEvent`} nativeEvent={this.nativeEventMinus} nativeFunc={this.nativeFuncMinus} elm={this.elm}/>
                </div>
              </div>
          </div>    

  }
}

  let materialdatasample = {
    "Sticker Vinyl Matte" : {
      value : "Sticker Vinyl Matte",
      meta_alias : "vinmatte",
      meta_category : "Sticker"
    },
    "Sticker Vinyl Glossy" : {
      value : "Sticker Vinyl Glossy",
      meta_alias : "vinglossy",
      meta_category : "Sticker"
    },
    "Laminasi Panas Glossy" : {
      value : "Hot Glossy",
      meta_alias : "Lam. P Glossy",
      meta_category : "laminating"
    },
    "Laminasi Panas Matte" : {
      value : "Hot Matte",
      meta_alias : "Lam. P Matte",
      meta_category : "laminating"
    },
    "Laminasi Dingin Glossy" : {
      value : "Cold Glossy",
      meta_alias : "Lam. D Glossy",
      meta_category : "laminating"
    }
  }


class SelectList {
  constructor(_custom) {
    
    this.inner = Object.keys(_custom.options);

    this.options = this.inner.map(opt => {

      let _opt = _custom.options[opt];

      return <option meta_alias={_opt.meta_alias} meta_category = {_opt.meta_category} value={_opt.value}>{opt}</option>;
    }) || '';

    this.id = _custom.id || ''

    this.class = _custom.class || ''

    this.selector = (<select className={_custom.class} id={_custom.id}>
                      {this.options}
                    </select>)

    if (_custom.controller) {

      this.optionstring = this.inner.map(opt => {

        let _opt = _custom.options[opt];

        return `<option meta_alias=${_opt.meta_alias} meta_category = ${_opt.meta_category} value=${_opt.value}>${opt}</option>`;
      }) || '';

      let selElm = `<select class="${_custom.class}">
                        ${this.optionstring}
                    </select>`;

      this.selector = (
          <div className='container col' id='materialcont' ctrlmeta={btoa(selElm)}>
            <select className={_custom.class} id={_custom.id}>
                  {this.options}
              </select>
          </div>
        );

    }
   
    return this.selector;
  }
}
const toRupiah = (_num) => {
  return `Rp ${new Intl.NumberFormat('de-DE').format(_num)};`
}
const countPen = (_minute) => {
  return `${toRupiah(_minute * 1000)} ( ${_minute} menit )`
}
const countPoin = (_minute) => {
  return `${toRupiah(_minute * 1000)} ( ${_minute} menit )`
}

const Loader = <i className='fa fa-spinner _spinIt'/>;

function _modalShowSin(_state, _sets){


  const [_get_sin_chunk, _set_sin_chunk] = useState(null);

  const [_get_profile_body, _set_profile_body] = useState(Loader);

  
  useEffect(() => {

    const _sinEP = `https://checklist.ptohpnk.com/rejiohrelwiuarheuh/20230201/20230231/${document.cookie}/dtl`;
    
    fetch(_sinEP)
    .then(_resp =>
      _resp.json()

    )
    .then(
      _sindata => {

        _set_sin_chunk(_sindata);
        _set_profile_body(
          loadedModalContent(_sindata)
        );
      }
    )
  }, [])

  function center(data) {
    return <p className='text-center bold'>{data}</p>
  }
  function ctrTitle(data) {
    return <h5 className='text-center bold'>{data}</h5>
  }
  function bold(data) {
    return <span style={{"fontWeight":"bold"}}>{data}</span>
  }

  function loadedModalContent(sindatas) {


    let tableTitle = [{
      title : "#",
      width : 2,
      dataIndex : "no",
      fixed : "left"
    },
    {
      title : <div className='text-center'>
                {ctrTitle("Info Kesalahan")}
                <Tag color={"red"}>bahan</Tag>
                <Tag color={"green"}>ongkir</Tag>
                <Tag color={"blue"}>lainnya</Tag>
              </div>,
      width : 10,
      dataIndex : "ttl"
    },
    {
      title : ctrTitle("Tgl Salah"),
      width : 5,
      dataIndex : "waktu",
    },
    {
      title : ctrTitle("Sebab"),
      width : 5,
      dataIndex : "sebab"
    },
    {
      title : ctrTitle("Detail Bahan"),
      width : 10,
      dataIndex : "detail"
    }
  ];

    let pointer = 0;
  
    let tableContent = sindatas.map(_data => {
      pointer++;

      let _comp_elm = {
        no : pointer,
        ttl : <div>
                <h4>{toRupiah(_data.denda_ongkir + _data.denda_lainnya + _data.denda_bahan)}</h4>
                <p>
                <Tag color={"red"}>{bold(toRupiah(_data.denda_bahan))}</Tag>
                <Tag color={"green"}>{bold(toRupiah(_data.denda_ongkir))}</Tag>
                <Tag color={"blue"}>{bold(toRupiah(_data.denda_lainnya))}</Tag>
                </p> 
                <p>
                  {_data.detail}
                </p>
              </div>,
        waktu : `${new Date(_data.tglkesalahan).localstr("  ")} ${_data.jamkesalahan}`,
        detail : <div className='text-center'>
                    {["xxx", "xx", "xx"].map( bhn => {
                      return <span>{bhn} 
                                <Tag color={"red"}>1</Tag>
                              </span>
                    })}
                </div>,
        sebab : center(_data.sebab)
      }    


      return _comp_elm;
    })
    

    let sinsTable = <Table
                        columns={tableTitle}
                        dataSource={tableContent}
                        scroll={{
                          x : 1300
                        }}
                      />

    let modalContent = (
      <div className='container text-start rounded'>
        
        {sinsTable}

      </div>

    )
    

    return (
      <div>
        {modalContent}
      </div>
    )
  }

  

  function modalContainer() {

    return (
      <Modal show={_state}>
        <Modal.Header closeButton onClick={_sets}>
          <Modal.Title title='lapor kesalahan'>
            Buku Dosa Feb 2023
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='container-fluid text-center'> 
            {_get_profile_body}
          </div>
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
    </Modal>)    
  }

  return modalContainer()
}

function _modalProfile(_state, _sets){

  const [_get_sin_chunk, _set_sin_chunk] = useState(null);

  const [_get_profile_body, _set_profile_body] = useState(Loader);

    
    useEffect(() => {

    const _sinEP = `https://checklist.ptohpnk.com/rejiohrelwiuarheuh/20230201/20230231/${document.cookie}/ttl`;
    fetch(_sinEP)
    .then(_resp =>
      _resp.json()
    )
    .then(
      _sindata => {
        _set_sin_chunk(_sindata);
        _set_profile_body(
          loadedProfileModal(_sindata)
        );
      }
    )
  }, [])

  const [displayed_profilemodal, display_profilemodal] = useState(false);

  const closeModal = function () {
    display_profilemodal(false)
  }

  function loadedProfileModal(profiledata) {



    profiledata.ttl_kesalahan = profiledata.ttl_denda_bahan + profiledata.ttl_denda_ongkir + profiledata.ttl_denda_lainnya;

    const userprofiledata = {
      "Nama Lengkap" : profiledata.namalengkap,
      "NIK" : profiledata.nik,
      "Jabatan" : profiledata.jabatan,
      "Total Kesalahan" : toRupiah(profiledata.ttl_kesalahan), //rupiah
      "Denda Ongkir" : toRupiah(profiledata.ttl_denda_ongkir ), //rupiah
      "Denda Lainnya" : toRupiah(profiledata.ttl_denda_lainnya ), //rupiah
      "Denda Bahan" : toRupiah(profiledata.ttl_denda_bahan ), //rupiah
      "Denda Kehadiran" : countPen(0), //minute
      "Denda Istirahat" : countPen(0), //minute
      "Poin Lembur" : 0 // 1 / 30 menit
    }


    let _profilecontent = new TableBlue(userprofiledata)


    let myprofile = (
      <div className='container text-start rounded'>
        {_photoprofile}
        
        {_profilecontent}

      </div>


    )
    

    return (
      <div>
        {myprofile}
      </div>
    )
  }

  

  function modalProfile() {
    return (
      <Modal show={_state}>
        <Modal.Header closeButton onClick={_sets}>
          <Modal.Title title='lapor kesalahan'>
            My Profile
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='container-fluid text-center'> 
            {_get_profile_body}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => {
            window.location.reload()
          }}>
            Sign Out
          </Button>
        </Modal.Footer>
    </Modal>)    
  }

  return modalProfile()
}


function _modalSubmitSin(_state, _sets){

// function _modalSubmitSin(_state, _sets){



  function lessemp () {
    _setemp(_getemp-1)
  }

  const [_get_sin_chunk, _set_sin_chunk] = useState(null);


  // const _sinEP = `http://127.0.0.1:7777/chunkall`;
  const _sinEP = `https://checklist.ptohpnk.com/chunkall`;
  
  const[_getemp, _setemp] = useState(1);
  const[_getselMaterial, _setselMaterial] = useState(1);
  const[_getMaterial, _setMaterial] = useState(1);



  useEffect(() => {
    fetch(_sinEP)
    .then(_resp =>
      _resp.json()
    )
    .then(
      _sindata => {
        _set_sin_chunk(_sindata);
      }
    )
  }, [_getemp])

  function LoadedSinModal() {

    let currentUserData = {
      name : "KAI",
      div : "IT",
    }


    let oknum = new InputList({fieldclass : "text-center packing p-1", inputclass:"oknuminp", value:`${currentUserData.name}@${currentUserData.div}`, fieldname:"Oknum", controller : true});
    let custName = new InputList({fieldname:"Nama Customer"});
    let orderDate  = new InputList({placeholder:"dd-mm-yyyy", fieldname:"Tanggal Order"});
    let orderMpl = new InputList({placeholder:"Marketplace", fieldname:"Marketplace"});
    let machine = new InputList({placeholder:"Mesin", fieldname:"Mesin"});
    let cause = new InputList({placeholder:"Penyebab", fieldname:"Penyebab"});
    let action = new InputList({placeholder:"Action", fieldname:"Action"});
    let shipcost = new InputList({fieldclass : "text-center packing p-1", type:'number', placeholder:"0", fieldname:"Denda Ongkir"});
    let othercost = new InputList({fieldclass : "text-center packing p-1", type:'number', placeholder:"0", fieldname:"Denda Lainnya"});

    let material = new SelectList({ controller:true, options : materialdatasample});

    let _paperside = {
      "1" : {
        value : "1"
      },
      "2" : {
        value : "2"
      }
    }
    
    let paperside = new SelectList({ options : _paperside});

    let keys = {
      karyawan : {
        _get : _getemp,
        _set : _setemp,
        _elm : oknum
      },
      bahanGroup : {
        _get : _getselMaterial,
        _set : _setselMaterial,
        _elm : mtrBundle
      },
      bahan : {
        _get : _getMaterial,
        _set : _setMaterial,
        _elm : material
      }
    }



    function renderelm(selectedElm) {
      let elm = [];

      let spotedElm = keys[selectedElm];

      for(let el = 0; el < spotedElm._get; el++){
        elm.push(el)
      }
    
      return elm.map( _i => {
        return spotedElm._elm})
    }

    function pilihBahan() {
      let elm = [];

      for(let el = 0; el < _getMaterial; el++){
        elm.push(el)
      }
    
      return elm.map( _i => {
        return material})
    }

    function groupBahan() {
      let elm = [];

      for(let el = 0; el < _getselMaterial; el++){
        elm.push(el)
      }
    
      return elm.map( (_i,_ikey) => {
        return mtrBundle(_ikey)})
    }


    function mtrBundle(_key) {

      let mtrelm = (<div className='materialbundlecont shadow p-2'>
                      <div className='row materialbundle'>
                        <div className='col'>
                          <div className='row my-1'>
                            {pilihBahan()}
                          </div>
                          <div className='row my-1'>
                          <div className='row m-1 text-end'>
                            <div className={`col-6 col text-end`}>
                            </div>
                            <div className={`col-6 col text-end`}>
                              <div className='row text-end'>
                                <i key={_key} class={`mx-1 fa col-5 fa-plus btn btn-danger`} onClick={() => { _setMaterial(_getMaterial+1)}}/>
                                <i key={_key} class={`mx-1 fa col-5 fa-minus btn btn-danger`} onClick={() => {lessElm("bahan")}}/>
                              </div>
                            </div>
                          </div>  
                          </div>
                        </div>
                        <div className='col'>
                          <div className='row text-start'>
                            <div className='col'>
                              <p>Jml : </p>
                            </div>
                            <div className='col'>
                              <input type='number' style={{width:100}} min="0" placeholder='1'/>
                            </div>
                          </div>
                          <div className='row text-start'>
                            <div className='col'>
                              <p>Sisi : </p>
                            </div>
                            <div className='col'>
                              {paperside}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>)

    return <div id='mtrbndcont'>
              {mtrelm}
            </div>

    }

    function lessElm(selectedElm) {

      let spotedElm = keys[selectedElm];

      if(spotedElm._get > 1) {
        spotedElm._set(spotedElm._get-1)
      }
    }


    return (
      <div>
        {renderelm("karyawan")}
        <div className='row m-1 text-end'>
          <div className={`col-6 col text-end`}>
          </div>
          <div className={`col-6 col text-end`}>
            <div className='row text-end'>
              <i class={`mx-1 fa col-3 fa-plus btn btn-primary`} onClick={() => { _setemp(_getemp+1)}}/>
              <i class={`mx-1 fa col-3 fa-minus btn btn-primary`} onClick={() => {lessElm("karyawan")}}/>
            </div>
          </div>
        </div>  
        {custName}
        {orderDate}
        {orderMpl}
        {machine}
        {cause}
        {action}
        {shipcost}
        {othercost}
        <div className='row text-start'>
          <p style={{"font-weight":"bold"}}>Kesalahan</p>
        </div>
        {groupBahan()}
        <div className='row m-1 text-end'>
          <div className={`col-6 col text-end`}>
          </div>
          <div className={`col-6 col text-end`}>
            <div className='row text-end'>
              <i class={`mx-1 fa col-3 fa-plus btn btn-secondary`} onClick={() => { _setselMaterial(_getselMaterial+1)}}/>
              <i class={`mx-1 fa col-3 fa-minus btn btn-secondary`} onClick={() => {lessElm("bahanGroup")}}/>
            </div>
          </div>
        </div>  
      </div>
    )




  }

  

  function modalSin() {
    return (
      <Modal show={_state}>
        <Modal.Header closeButton onClick={_sets}>
          <Modal.Title title='lapor kesalahan'>
            Lapor Kesalahan
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='container-fluid text-center'> 
            {LoadedSinModal()}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" className='nativeEvent' nativeEvent='click' nativeFunc='submitKesalahan'>
            Submit Dosa
          </Button>
        </Modal.Footer>
    </Modal>)    
  }

  return modalSin()
}


export default Gate;


const _bln = ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'];
Date.prototype.bulan = function () {
    return _bln[this.getMonth()];
}

Date.prototype.tglJam = function (_sp) {
    let _strind = `${dd(this.getDate())}${_sp}${this.bulan()}${_sp}${this.getFullYear()} ${this.time()}`;
    return _strind;
}

Date.prototype.time = function () {
  let _mysql = `${this.getHours()}:${this.getMinutes()}:${this.getSeconds()}`;
  return _mysql;
}

Date.prototype.localstr = function () {
  let _mysql = `${dd(this.getDate())} ${this.bulan()} ${this.getFullYear()}`;
  return _mysql;
}


const dd = (_i) => {
  if(/^\d$/.test(_i)){
    return `0${_i}`
  } else if(!/^d$/.test(_i)){
    return `${_i}`
  }
}


function _docNav() {
  const sampleDate = {
    mpl : ["tokopedia", "shopee", "whatsapp", "bukalapak"],
    tokopedia : ["12 Januari 2023", "13 Januari 2023", "14 Januari 2023"]
  }
  return (
    <div className="row">
      <div className="col-12 col-sm-5 col-md-7 col-lg-7">
          <div className="row">
              <select name="navmpl" id="navmpl">
                {
                  sampleDate.mpl.map( mpl => {
                      return <option value={mpl}>{mpl}</option> 
                    }
                  )
                } 
                
              </select> 
              <select name="navdate" id="navdate">
                {
                  sampleDate[sampleDate.mpl[0]].map(_date => 
                    {
                      return <option value={_date}>{_date}</option>
                    })
                }
              </select>
          </div>
      </div>
      <div className="col-12 col-sm-7 col-md-5 col-lg-5">
          <div className="row">
              <div className="col-10">
                  <input list="inv60" name="invcode" id="invcode" placeholder="Nomer Invoice"/>
              </div>
              <div className="col-2">
                  <button className="btn btn-default" onclick="orderInfo()">Cari</button>
              </div>
          </div>
      </div>
    </div>
  );
}

function _chart() {

  let samplestate = {
    options: {
      chart: {
        id: "basic-bar"
      },
      xaxis: {
        categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999]
      }
    },
    series: [
      {
        name: "series-1",
        data: [30, 40, 45, 50, 49, 60, 70, 91]
      }
    ]
  };
  
  return <Chart
    options={samplestate.options}
    series={samplestate.series}
    type="bar"
    width="500"
  />
}
