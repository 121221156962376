import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Gate from './App';
import reportWebVitals from './reportWebVitals';
import { useState } from 'react';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Gate />
);

const ById = (_id) => {
  return document.getElementById(_id);
}

const ByClass = (_class) => {
  return Array.from(document.getElementsByClassName(_class))
}


const bindedFunc = {

  emptyCallback : () => {
    alert('undefined callback name')
  },
  submitKesalahan : () => {
    alert('submitting kesalahan')
  },

  moreElm : (e) => {
    let controledElm = e.target.getAttribute('elm');

    let bundlecont = ById(controledElm);
  
    let lastnum = bundlecont.children.length;
  
    let innerbundle = atob(bundlecont.getAttribute('ctrlmeta'));
      innerbundle = innerbundle.replace('penbundle#0', `penbundle#${lastnum}`).replaceAll('(0)',`(${lastnum})`);
  
    bundlecont.innerHTML += innerbundle;
  },

  lessElm : (e) => {
    let controledElm = e.target.getAttribute('elm');

    let penmtrcont = ById(controledElm);
    let lastnum = penmtrcont.children.length - 1;
    let lastmaterial = Array.from(penmtrcont.children)[lastnum];
  
    if(lastnum > 0){
      lastmaterial.remove();
    }
    
  }
}


window.onload = () => {

  ByClass('nativeEvent').forEach( _elm => {
    let _event = _elm.getAttribute("nativeEvent")
    let _func = _elm.getAttribute("nativeFunc");

    _elm.addEventListener(_event, (e) => {
    
      bindedFunc[_func](e)   
    
    })
    
  })

}

const signIn = () => {
  Array.from(document.getElementsByClassName("_logo")).forEach(_elm => {
      _elm.addEventListener('click', (_e) => {
        alert(_e)
    })
  })
}


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
